import { ReactNode } from "react";
import styled from "styled-components";

const StyledItem = styled.span`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledLabel = styled.span`
  margin-right: 5px;
`;

type Props = {
  label: string;
  children?: ReactNode;
};

export function MetaItem(props: Props): JSX.Element {
  const { label, children } = props;
  return (
    <StyledItem>
      <StyledLabel>{label}</StyledLabel>
      <span>{children}</span>
    </StyledItem>
  );
}
