import { useMemo } from "react";
import { WindowLocation } from "@reach/router";
import styled, { ThemeProvider } from "styled-components";
import {
  BlogGrid,
  defaultTheme,
  Metadata,
  Pagination
} from "@joshuaavalon/component";
import { SimplePost } from "@joshuaavalon/model";

import { useSiteMetadata } from "../hook";

const StyledBlog = styled.div`
  margin: ${props => props.theme.dimen.mainMargin};
  margin-bottom: 0;
  flex: 1;
`;

const StyledPagination = styled(Pagination)`
  align-items: flex-end;
`;

function getTitle(current: number): string {
  return current === 1 ? "Home" : `Page ${current}`;
}

type Props = {
  posts: SimplePost[];
  page: {
    current: number;
    next?: string;
    prev?: string;
  };
  location: WindowLocation;
  onTitle?: (current: number) => string;
};

export function BlogLayout(props: Props): JSX.Element {
  const { posts, page, location, onTitle = getTitle } = props;
  const { pathname } = location;
  const { current, next, prev } = page;
  const title = useMemo(() => onTitle(current), [current, onTitle]);
  const siteMetadata = useSiteMetadata();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Metadata siteMetadata={siteMetadata} title={title} pathname={pathname} />
      <StyledBlog>
        <BlogGrid posts={posts} />
        <StyledPagination next={next} prev={prev} />
      </StyledBlog>
    </ThemeProvider>
  );
}
