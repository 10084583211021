import { useMemo } from "react";
import styled from "styled-components";
import { WindowLocation } from "@reach/router";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import { Article, Comment, Container, Metadata } from "@joshuaavalon/component";
import { Post } from "@joshuaavalon/model";

import { PostMeta } from "./post-meta";
import { useSiteMetadata } from "../../hook";

const StyledSection = styled.section`
  width: 100%;
  max-width: ${props => props.theme.dimen.maxContentWidth};
  margin: 0 auto;
`;

const StyledImage = styled(GatsbyImage)`
  margin-top: ${props => props.theme.dimen.mainMargin};

  @media only screen and (min-width: calc(${props =>
      props.theme.dimen.maxContentWidth} + 1px)) {
    border-radius: 7px;
  }
`;

const StyledTitle = styled.h1`
  font-family: ${props => props.theme.font.header};
`;

const StyledComment = styled(Container)`
  min-height: 300px;
`;

const StyledMetadata = styled(PostMeta)`
  margin: 20px 0;
`;

type Props = {
  post: Post;
  location: WindowLocation;
};

export function PostLayout(props: Props): JSX.Element {
  const { post, location } = props;
  const { origin, pathname } = location;
  const {
    title,
    cover,
    tags,
    updateDate,
    publishDate,
    canonical,
    excerpt
  } = post;
  const { gatsbyImageData: image } = cover;
  const meta = useMemo(
    () => [
      {
        property: "article:published_time",
        content: new Date(publishDate).toISOString()
      },
      {
        property: "article:modified_time",
        content: new Date(updateDate).toISOString()
      }
    ],
    [publishDate, updateDate]
  );
  const siteMetadata = useSiteMetadata();
  return (
    <>
      <Metadata
        title={title}
        description={excerpt}
        keywords={tags}
        meta={meta}
        pathname={pathname}
        canonical={canonical}
        image={getSrc(image)}
        siteMetadata={siteMetadata}
      />
      <StyledSection>
        <StyledImage image={image} alt={title} />
        <Container padding margin>
          <StyledTitle>{title}</StyledTitle>
          <StyledMetadata post={post} />
          <Article post={post} />
        </Container>
        <StyledComment margin padding>
          <Comment origin={origin} pathname={pathname} title={title} />
        </StyledComment>
      </StyledSection>
    </>
  );
}
