import styled from "styled-components";
import { Tags } from "@joshuaavalon/component";
import { Post } from "@joshuaavalon/model";

import { MetaItem } from "./meta-item";

const StyledRow = styled.div`
  font-size: 14px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const StyledTags = styled(Tags)`
  justify-content: flex-start;
`;

const StyledMetadata = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${props => props.theme.font.system};
  color: ${props => props.theme.color.textSecondary};
`;

type Props = {
  post: Post;
  className?: string;
};

export function PostMeta(props: Props): JSX.Element {
  const { post, className } = props;
  const { updateDate, publishDate, tags } = post;
  return (
    <StyledMetadata className={className}>
      <StyledRow>
        <StyledTags tags={tags} />
      </StyledRow>
      <StyledRow>
        <MetaItem label="Published at">{publishDate}</MetaItem>
        <MetaItem label="Updated at">{updateDate}</MetaItem>
      </StyledRow>
    </StyledMetadata>
  );
}
